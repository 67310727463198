
import {useContext, ReactNode} from 'react';
import {ListItemText, MenuItem, Box, Grid, Checkbox} from '@mui/material';
import { t } from 'i18next';

import { CardSectionTitle, TextField } from 'components/ui';

import { CharacterContext } from 'contexts';

type Props = {
    readonly: boolean
}

export function Other(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id="Generic.Other" />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="careerAspiration"
                        defaultValue={char?.careerAspiration}
                        label={t('Components.PlayerCharacters.Career')}
                        multiline
                        onChange={(event) => setChar({...char, careerAspiration: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="favoriteSportsTeam"
                        defaultValue={char?.favoriteSportsTeam}
                        label={t('Components.PlayerCharacters.FavoriteSportsTeam')}
                        multiline
                        onChange={(event) => setChar({...char, favoriteSportsTeam: event.target.value})}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="greatestFear"
                        defaultValue={char?.greatestFear}
                        label={t('Components.PlayerCharacters.GreatestFear')}
                        multiline
                        onChange={(event) => setChar({...char, greatestFear: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="greatestDream"
                        defaultValue={char?.greatestDream}
                        label={t('Components.PlayerCharacters.GreatestDream')}
                        multiline
                        onChange={(event) => setChar({...char, greatestDream: event.target.value})}
                    />
                </Grid>
                
                <Grid item xs={6}>
                    <MenuItem
                        sx={{
                            backgroundColor: '#1e1e1e',
                            color: '#fff',
                            fontFamily: 'Neuton',
                            '&:hover': {
                                backgroundColor: '#303030',
                            },
                        }}
                        onClick={() => (!props.readonly && setChar({...char, canSeeThestrals: !char?.canSeeThestrals}))}
                    >
                        <Checkbox 
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: 'primary.main'
                                }
                            }} 
                            checked={char?.canSeeThestrals} 
                        />
                        <ListItemText primary={t('Components.PlayerCharacters.CanSeeThestrals')} />
                    </MenuItem>
                </Grid>
            </Grid>
        </Box>
    );
}
