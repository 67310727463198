import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/ui';
import { Headline, SignupForm } from 'components/thestral';

import { HTTP_METHOD, SIGNUP_URL } from 'const';
import { useFetchFromBackend, useSnackbar } from 'func';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required(t('Components.Register.Required')),
  lastname: yup
    .string()
    .required(t('Components.Register.Required')),
  email: yup
    .string()
    .required(t('Components.Register.Required')),
  dateOfBirth: yup
    .date()
    .required(t('Components.Register.Required')),
  description: yup
    .string()
    .required(t('Components.Register.Required')),
  houses: yup
    .string()
    .required(t('Components.Register.Required')),
  discord: yup
    .string()
    .optional(),
  pronouns: yup
    .string()
    .optional(),
  nickname: yup
    .string()
    .optional(),
  privacy: yup
    .boolean()
    .oneOf([true], t('Components.Register.Required')),
  nextCon: yup
    .boolean()
    .oneOf([true], t('Components.Register.Required')),
});

export function Signup() {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const nav = useNavigate(); 
  const formik = useFormik({
    initialValues: {
      legal: false,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values)
  });

  const onSubmit = (values) => {
    const body = JSON.stringify(values);
    fetchFromBackend(SIGNUP_URL, {method: HTTP_METHOD.POST, body})
    .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        let result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          result += t('Components.Register.Success');
        }
        showSnackbar(`${t('Components.Register.Submit')} ${result}`, severity);
        if (response.ok) {
          setTimeout(() => nav('/'), 3000);
        }
    }).catch((error) => {
      if (error.statusCode == 404) {
        showSnackbar('Invalid Email', 'error');
      }
    });
  };

  return (
    <Box className="content" sx={{marginTop: '50px', width: '900px'}}>
      <Headline title={t('Components.Greeting.Welcome')} marginBottom='10px' />
      <Typography component="div" variant="h6">
        {t('Components.Signup.Caption')}
      </Typography>
      <Typography component="div" variant="caption">
        {t('Components.Signup.DescriptionText')}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <SignupForm formik={formik} />

        <Button 
          color="success" 
          onClick={formik.submitForm} 
          sx={{marginTop: '20px'}} 
          text={t('Generic.Save')} 
        />
      </form>
    </Box>
  );
}
