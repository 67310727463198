import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Archive } from '@mui/icons-material';
import { t } from 'i18next';

import { ApprovalGrid, DraperyCheckList, Headline } from 'components/thestral';
import { Button, Dialog } from 'components/ui';
import { Character } from 'types';

export function DraperyApproval() {
  const [open, setOpen] = useState<boolean>(false);
  const [openArchive, setOpenArchive] = useState<boolean>(false);
  const [current, setCurrent] = useState<Character>(undefined);
  const [filter, setFilter] = useState<string[]>();

  const breadcrumbs = [
    {href: '/', title: t('Generic.Starl')},
  ];

  function onRowClick(elem) {
    setCurrent(elem.row);
    setOpen(true);
  }

  function onRowClickPending(elem) {
    setFilter(['PENDING']);
    onRowClick(elem);
  }

  function onRowClickArchive(elem) {
    setFilter(['DECLINED', 'APPROVED']);
    onRowClick(elem);
  }

  const onClose = () => {
    setOpen(false);
    setCurrent(undefined);
  };

  return (
    <>
      <Box sx={{marginTop: '50px'}}>
        <Headline title={t('Components.Drapery.Approvals.Title')} breadcrumbs={breadcrumbs} />

        <Grid container spacing={2}>
          <Grid item xs={12} sx={{maxHeight: '50px'}}>
            <Button 
              sx={{marginBottom: '40px'}} 
              onClick={() => setOpenArchive(true)} 
              color="secondary" 
              startIcon={<Archive />}   
              text={<Trans i18nKey="Components.Drapery.Approvals.Archive" />}
            />
          </Grid>
          <Grid item xs={12}>
            <ApprovalGrid onRowClick={onRowClickPending} isDrapery />  
          </Grid>
        </Grid>

        {current && 
          <DraperyCheckList open={open} person={current} onClose={onClose} filter={filter} />
        }
      </Box>

      <Dialog 
        open={openArchive}
        onClose={() => setOpenArchive(false)}
        title={t('Components.Drapery.Approvals.Archive')}
        content={
          <ApprovalGrid onRowClick={onRowClickArchive} isDrapery isArchivedDrapery /> 
        }
      />
    </>
  );
}
