import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { FormikProps, FormikValues } from 'formik/dist/types';
import { Trans } from 'react-i18next';

import { GridHeadline, ThestralCheckbox } from 'components/thestral';
import { DateField, LinkText, TextField } from 'components/ui';

import { Event } from 'types';
import { EVENTS_URL } from 'const';
import { parseDate, useFetch } from 'func';

type Props = {
  formik: FormikProps<FormikValues>
}

export function SignupForm(props: Readonly<Props>) {
  const formik = props.formik;
  const { data } = useFetch<Event>(`${EVENTS_URL}/next`);

  return (
    <Box sx={{marginTop: '50px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridHeadline title={t('Components.Signup.Data')} />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="firstname"
            label={t('Components.Profile.PersonalData.LegalData.FirstName')}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.firstname)}
            helperText={formik.errors.firstname as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="lastname"
            label={t('Components.Profile.PersonalData.LegalData.LastName')}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.lastname)}
            helperText={formik.errors.lastname as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="nickname"
            label={t('Generic.Nickname')}
            value={formik.values.nickname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.nickname)}
            helperText={formik.errors.nickname as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="pronouns"
            label={t('Generic.Pronouns')}
            value={formik.values.pronouns}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.pronouns)}
            helperText={formik.errors.pronouns as string}
          />
        </Grid>
        <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
          <DateField 
            label={t('Generic.DateOfBirth')} 
            minDate="1970-01-01" 
            disableFuture 
            value={formik.values.dateOfBirth}
            error={Boolean(formik.errors.dateOfBirth)}
            helperText={formik.errors.dateOfBirth as string}
            onChange={(value) => formik.setFieldValue('dateOfBirth', value.toISODate())}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="discord"
            label={t('Components.Signup.Discord')}
            value={formik.values.discord}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.discord)}
            helperText={formik.errors.discord as string}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="description"
            label={t('Components.Signup.Description')}
            multiline
            rows={10}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            helperText={formik.errors.description as string}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="houses"
            label={t('Components.Signup.Houses')}
            value={formik.values.houses}
            multiline
            rows={10}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.houses)}
            helperText={formik.errors.houses as string}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField 
            id="why"
            label={t('Components.Signup.Why')}
            value={formik.values.why}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.why)}
            multiline
            rows={12}
            helperText={formik.errors.why as string}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="experience"
            label={t('Components.Signup.Experience')}
            value={formik.values.experience}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.experience)}
            multiline
            rows={12}
            helperText={formik.errors.experience as string}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField 
            id="affiliate"
            label={t('Components.Signup.Affiliate')}
            value={formik.values.affiliate}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.affilate)}
            helperText={formik.errors.affilaite as string}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            id="other"
            label={t('Components.Signup.Other')}
            value={formik.values.other}
            multiline
            rows={10}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.other)}
            helperText={formik.errors.other as string}
          />
        </Grid>

        <Grid item xs={4}>
          <GridHeadline title={t('Components.Signup.Privacy')} />

          <Typography variant="caption" component="div">
            <Trans 
              i18nKey="Components.Signup.PrivacyText"
              components={{
                url: <LinkText to='/privacy' target="_blank" />
              }} 
            />
          </Typography> 
        </Grid>
        <Grid item xs={4}>
          <GridHeadline title={t('Components.Signup.WaitingList')} />

          <Typography variant="caption" component="div">
            <Trans 
              i18nKey="Components.Signup.WaitingListText"
            />
          </Typography> 
        </Grid>
        <Grid item xs={4}>
          <GridHeadline title={t('Components.Signup.NextCon')} />

          <Typography variant="caption" component="div">
            <Trans 
              i18nKey="Components.Signup.NextConText"
            />
          </Typography> 
          {`${t('Generic.Date')}: ${parseDate(data?.start)} - ${parseDate(data?.end)}`}
        </Grid>

        <Grid item xs={4}>
          <ThestralCheckbox 
            id="privacy"
            text={t('Components.Signup.Privacy')} 
            checked={formik.values.privacy}
            onChange={(event) => formik.setFieldValue('privacy', event.target.checked)}
          />
          {Boolean(formik.errors.privacy) &&
            <Typography color="error" component="div" variant="caption">
              {typeof formik.errors.privacy === 'string' && formik.errors.privacy}
            </Typography>          
          }
        </Grid>
        <Grid item xs={4}>
          <ThestralCheckbox 
            id="waitingList"
            text={t('Components.Signup.WaitingList')} 
            checked={formik.values.waitingList}
            onChange={(event) => formik.setFieldValue('waitingList', event.target.checked)}
          />
          {Boolean(formik.errors.waitingList) &&
            <Typography color="error" component="div" variant="caption">
              {typeof formik.errors.waitingList === 'string' && formik.errors.waitingList}
            </Typography>          
          }
        </Grid>
        <Grid item xs={4}>
          <ThestralCheckbox 
            id="nextCon"
            text={t('Components.Signup.NextCon')} 
            checked={formik.values.nextCon}
            onChange={(event) => formik.setFieldValue('nextCon', event.target.checked)}
          />
          {Boolean(formik.errors.nextCon) &&
            <Typography color="error" component="div" variant="caption">
              {typeof formik.errors.nextCon === 'string' && formik.errors.nextCon}
            </Typography>          
          }
        </Grid>
      </Grid>
    </Box>
  );
}
