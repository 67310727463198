import { Check, Close, Diamond, Forest, MeetingRoom, PriceCheck, Verified } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';

import { ParticipationChip, ThestralDataGrid } from 'components/thestral';

import { HTTP_METHOD, EMPTY, HOUSE_FILTER, PLOT_FILTER, EVENTS_URL } from 'const';
import { Participant } from 'types';
import { useFetchFromBackend, useSnackbar } from 'func';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';

type Props = {
  participants: Participant[],
  eventId: number,
  triggerRefetch: () => void
}

export function Participations(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [participants, setParticipants] = useState<Participant[]>();

  const [activeChip, setActiveChip] = useState<string>();  

  useEffect(() => {
    if (participants != props.participants) {
      setParticipants(props.participants);
      setLoading(false);
    }
  }, [props.participants]);

  const onStatusChange = (elem, type) => {
    setLoading(true);
    fetchFromBackend(`${EVENTS_URL}/${props.eventId}/${type}/${elem.id}`, {
      method: HTTP_METHOD.PUT
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          const i18nKey = type == 'approve' ? 'Approval' : 'Cancel';
          showSnackbar(`${t('Components.Admin.Events.'+i18nKey)} ${result}`, severity);
        }
      )
      .catch((error) => console.error(error))
      .finally(() => {
        props.triggerRefetch();
        setLoading(false);
      });
  };

  const apiRef = useGridApiRef();
  const [filterCount, setFilterCount] = useState([]);

  const getFilteredRowsCount = useCallback(
    (filterModel) => {
      const { filteredRowsLookup } = apiRef.current.getFilterState(filterModel);
      return Object.keys(filteredRowsLookup).filter(rowId => filteredRowsLookup[rowId]).length;
    }, [apiRef]);

  useEffect(() => {
    if (participants?.length === 0) {
      return;
    }

    const houseCounts = HOUSE_FILTER.map(({ filterModel }) => getFilteredRowsCount(filterModel));
    const plotCounts = PLOT_FILTER.map(({ filterModel }) => getFilteredRowsCount(filterModel));

    setFilterCount({
      ...houseCounts,
      ...plotCounts
    });
  }, [apiRef, participants, getFilteredRowsCount]);

  const icon = (id: string): ReactNode => {
    switch (id) {
      case 'forest': return <Forest />;
      case 'artifacts': return <Diamond />;
      case 'rooms': return <MeetingRoom />;
    }

    return <></>;
  };

  const clickChip = (elem) => {
    if (elem.id === activeChip) {
      setActiveChip('');
    } else {
      setActiveChip(elem.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {HOUSE_FILTER.map((data, index) => {
          const count = filterCount[index];
          return (
            <ParticipationChip key={data.label} data={data} isActive={activeChip == data.id} count={count} apiRef={apiRef} onClick={() => clickChip(data)} />
          );
        })}
      </Grid>
      <Grid item xs={12}>
        {PLOT_FILTER.map((data, index) => {
          const count = filterCount[index];
          return (
            <ParticipationChip key={data.id} data={data} icon={icon(data.id)} isActive={activeChip == data.id} count={count} apiRef={apiRef} onClick={() => clickChip(data)} />
          );
        })}
      </Grid>

      <Grid item xs={12}>
        <ThestralDataGrid 
          apiRef={apiRef}
          sx={{
            minWidth: '200px'
          }}
          rows={participants || EMPTY}
          columns={[
            {field: 'nickname', headerName: t('Generic.Nickname'), flex: 1},
            {field: 'firstname', headerName: t('Components.Profile.PersonalData.FirstName'), flex: 1},
            {field: 'lastname', headerName: t('Components.Profile.PersonalData.LastName'), flex: 1},
            {field: 'email', headerName: t('Generic.Email'), flex: 1},
            {field: 'house', headerName: t('Components.Characters.House'), flex: 1,
              renderCell: (params: GridRenderCellParams) => 
                (params?.row.house ? t('Enum.House.' + params?.row.house) : '')
            },
            {field: 'plot', headerName: 'Plot', flex: 1,
              renderCell: (params: GridRenderCellParams) => 
                <IconButton color="primary">
                  {params?.row.plot === 'ARTIFACTS' &&
                    <Tooltip title={t('Enum.Plot.ARTIFACTS')}>
                      <Diamond />
                    </Tooltip>
                  }
                  {params?.row.plot === 'FOREST' &&
                    <Tooltip title={t('Enum.Plot.FOREST')}>
                      <Forest />
                    </Tooltip>
                  }
                  {params?.row.plot === 'ROOMS' &&
                    <Tooltip title={t('Enum.Plot.ROOMS')}>
                      <MeetingRoom />
                    </Tooltip>
                  }
                </IconButton>
            },
            {field: 'status', headerName: 'Status', flex: 1,
              renderCell: (params: GridRenderCellParams) => 
                <IconButton color="primary">
                  {params?.row.status === 'REGISTERED' &&
                    <Tooltip title={t('Enum.ParticipationStatus.REGISTERED')}>
                      <Check />
                    </Tooltip>
                  }
                  {params?.row.status === 'PAID' &&
                    <Tooltip title={t('Enum.ParticipationStatus.PAID')}>
                      <PriceCheck />
                    </Tooltip>
                  }
                  {params?.row.status === 'CANCELLED' &&
                    <Tooltip title={t('Enum.ParticipationStatus.CANCELLED')}>
                      <Close />
                    </Tooltip>
                  }
                </IconButton>
            },
            {
              field: 'actions', headerName: t('Generic.Actions'), flex: 1, 
              headerAlign: 'right', align: 'right', width: 75,
              renderCell: (params: GridRenderCellParams) =>
                <>
                  {params?.row.status !== 'PAID' &&
                    <IconButton color="primary">
                      <Tooltip title={`${t('Enum.ParticipationStatus.PAID')}?`}>
                        <Verified onClick={() => onStatusChange(params?.row, 'approve')} />
                      </Tooltip>
                    </IconButton>
                  }
                  {params?.row.status !== 'CANCELLED' &&
                    <IconButton color="primary">
                      <Tooltip title={t('Components.Admin.Events.Cancellation')}>
                        <Close onClick={() => onStatusChange(params?.row, 'cancel')} />
                      </Tooltip>
                    </IconButton>
                  }
                </>
            }
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 }
            },
            sorting: {
              sortModel: [{ field: 'lastname', sort: 'asc' }]
            }
          }}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}