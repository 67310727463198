import { useContext, ReactNode } from 'react';
import { Grid } from '@mui/material';
import * as DOMPurify from 'dompurify';

import { RulesBlock, RulesBlockLinks, RulesBlockSubtitle, RulesParagraph, RulesBlockTitle, RulesBlockCaption, RulesAccordion, RulesTableHead, RulesTable, RulesTableBody } from 'components/thestral';

import { JsonContext } from 'contexts';

type Props = {
  name?: string
}

export function TabContent(props: Readonly<Props>): ReactNode {
  const {json} = useContext(JsonContext);

  function getComponent(entry, key): ReactNode {
      switch(entry.type) {
        case 'title':
          return (<RulesBlockTitle title={entry.title} key={key} href={entry.href} id={entry.id} />);
        case 'subtitle':
          return (<RulesBlockSubtitle color={entry.color || '#ffffff'} title={entry.title} key={key} fontWeight={entry.fontWeight || '700'} />);
        case 'caption':
          return (<RulesBlockCaption caption={entry.caption} key={key} marginTop={entry.marginTop} />);
        case 'list':
          return (<RulesBlock items={entry.items} key={key} />);
        case 'links':
          return (<RulesBlockLinks items={entry.items} key={key} />);
        case 'paragraph':
          return (<RulesParagraph color={entry.color} text={entry.text} key={key} />);
        case 'accordion': {
          const content = entry.items.map(getComponent);
          return (<RulesAccordion title={entry.section} content={content} refKey={entry.key} key={key}/>);
        }
        case 'html': {
          const sanitize = DOMPurify.sanitize(entry.html);
          return <div dangerouslySetInnerHTML={{ __html: sanitize }} />;
        }
        case 'break':
          return (<br key={key} />);
        case 'accordionlist':
           return (
             entry.items.map((accordion, i) => 
              getComponent(accordion, i)
             )
           );
        case 'grid':
          return (
            <Grid container spacing={2} sx={{'& .MuiGrid-item': {paddingTop: 0}, paddingBottom: '10px'}} key={key}>
              {
                entry.items.map((gridItem, j) => 
                  <Grid item xs={4} key={j}>
                    {gridItem.items.map((subItem, k) => 
                      getComponent(subItem, k))}
                  </Grid>
                )
              }
            </Grid>
          );
        case 'table':
          return (
            <RulesTable 
              headers={<RulesTableHead headers={entry.headers} />} 
              items={<RulesTableBody rows={entry.items} />}  
              key={key}
            />
          );
      }

      return null;
  }

  return (
     <>
        {json?.[props.name]?.items.map((entry, i) => getComponent(entry, i))}
     </>
  );
}