import { Box } from '@mui/material';
import Wallpaper from 'assets/images/thestral_bg.png';

export function Background() {
  return (
    <Box 
      component="img"
      sx={{
        position: 'fixed',
        inset: 0,
        zIndex: '-1'
      }}
      src={Wallpaper}
    /> 
  );
}