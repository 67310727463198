import { ChangeEvent, useEffect, useContext, useState, ReactNode } from 'react';
import { SelectChangeEvent, Chip, Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';

import NoPortrait from 'assets/images/no_portrait.png';

import { Button, CardSectionTitle, DateField, SelectField, TextField } from 'components/ui';

import { CharacterContext } from 'contexts';
import { useFetch } from 'func';
import { Pinterest } from '@mui/icons-material';
import { STATIC_URL, TEXT_FIELD_BIG, THESTRAL_URL } from 'const';

type Props = {
  readonly: boolean
}

export function General(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);
    const [uploadPortrait, setUploadPortrait] = useState(null);

    const [showHint, setShowHint] = useState(0);

    const ageGroups = useFetch<string[]>(`${THESTRAL_URL}/age-groups`);
    const breeds = useFetch<string[]>(`${THESTRAL_URL}/breeds`);
    const houses = useFetch<string[]>(`${THESTRAL_URL}/houses`);

    useEffect(() => {
        setShowHint(0);
    }, []);

    const changeSelectField = (event: SelectChangeEvent, key: string) => {
      const value = event.target.value;
      const newChar = {...char, [key]: value};
      setChar(newChar);
    };

    const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
        setShowHint(1);
        setUploadPortrait(event.target.files[0]);
        setChar({...char, newPortrait: event.target.files[0]});
    };

    const socialMedia = (channel: string) => {
        if (char?.socialMediaAccounts) {
            return char?.socialMediaAccounts[channel] || null;
        }

        return '';
    };

    const approvalChip = (): ReactNode => {
        if (!char?.state) 
            return '';

        switch (char?.state) {
            case 'UPDATED':
            case 'SUBMITTED':
             return (<Chip color='primary' label={t('Enum.CharApproval.'+char?.state)} />);
            case 'APPROVED':
                return (<Chip color='success' label={t('Enum.CharApproval.'+char?.state)} />);
            case 'DECLINED':
                return (<Chip color='error' label={t('Enum.CharApproval.'+char?.state)} />);
        }
        return (<Chip color='info' label={t('Enum.CharApproval.'+char?.state)} />);
    };

    const getMaxDate = (): string => {
        if (!char?.ageGroup) {
            return '';
        }

        return char?.ageGroup === 'FIFTH' ? '1982-08-31' : '1983-08-31';
    };

    const getMinDate = (): string => {
        if (!char?.ageGroup) {
            return '';
        }

        return char?.ageGroup === 'FIFTH' ? '1981-09-01' : '1982-09-01';
    };

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id="Generic.General" />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                readonly={props.readonly}
                                id="name"
                                defaultValue={char?.name}
                                label={t('Components.PlayerCharacters.Name')}
                                onChange={(event) => setChar({...char, name: event.target.value})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                readonly={props.readonly}
                                id="nickname"
                                defaultValue={char?.nickname}
                                label={t('Generic.Nickname')}
                                onChange={(event) => setChar({...char, nickname: event.target.value})}
                            />
                        </Grid>

                        <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                            <TextField
                                readonly={props.readonly}
                                id="playerName"
                                defaultValue={char?.playerName}
                                label={t('Components.PlayerCharacters.PlayerName')}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                            <TextField
                                readonly={props.readonly}
                                id="pronouns"
                                defaultValue={char?.inTimePronoun}
                                label={t('Generic.Pronouns')}
                                onChange={(event) => setChar({...char, inTimePronoun: event.target.value})}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {breeds && 
                            <SelectField
                                readonly={props.readonly}
                                id="breed"
                                label={t('Components.PlayerCharacters.Breed')}
                                value={[char?.breed]}
                                list={breeds.data}
                                i18nKey="Enum.Breed"
                                onChange={(event) => changeSelectField(event, 'breed')}
                            />
                            }
                        </Grid>

                        <Grid item xs={6}>
                            {ageGroups && 
                            <SelectField
                                readonly={props.readonly}
                                id="agegroup"
                                label={t('Components.PlayerCharacters.AgeGroup')}
                                value={[char?.ageGroup]}
                                list={ageGroups.data?.filter((age) => ['FOURTH', 'FIFTH'].includes(age))}
                                i18nKey="Enum.AgeGroup"
                                onChange={(event) => changeSelectField(event, 'ageGroup')}
                            />
                            }
                        </Grid>

                        <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <DateField 
                                    label={t('Generic.DateOfBirth')}
                                    value={char?.dateOfBirth}
                                    onChange={(newValue) =>  setChar({...char, dateOfBirth: newValue.toISODate()})}
                                    readonly={props.readonly}
                                    maxDate={getMaxDate()}
                                    minDate={getMinDate()}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            {houses && 
                            <SelectField
                                readonly={props.readonly}
                                id="house"
                                label={t('Components.Characters.House')}
                                value={[char?.house]}
                                list={houses.data?.filter((house) => house != 'NOHOUSE')}
                                i18nKey="Enum.House"
                                onChange={(event) => changeSelectField(event, 'house')}
                            />
                            }
                        </Grid>

                        {!char?.new  && socialMedia('SPOTIFY') &&
                        <Grid item xs={12}>
                            <Box sx={{maxHeight: '90px'}}>
                                <iframe
                                    title="Spotify Playlist for PlayerCharacter"
                                    src={'https://open.spotify.com/embed/playlist/' + socialMedia('SPOTIFY') + '?utm_source=generator'}
                                    width="100%"
                                    height="55%"
                                    allowFullScreen
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    style={{
                                        border: '0'
                                    }}
                                />
                            </Box>
                        </Grid>
                        }

                        {!props.readonly &&
                            <Grid item xs={12}>
                                <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    {approvalChip()}
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container>
                        {!char?.new && socialMedia('PINTEREST') &&
                        <Grid item xs={12}>
                            <Typography component="p" sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Chip
                                    component="a"
                                    href={socialMedia('PINTEREST').includes('/') ? `https://pinterest.de/${socialMedia('PINTEREST')}` : `https://pin.it/${socialMedia('PINTEREST')}`}
                                    color="error"
                                    icon={<Pinterest />}
                                    label={t('Components.PlayerCharacters.ToPinterest')}
                                    clickable
                                    target="_blank"
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </Typography>
                        </Grid>}
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& :hover': {backgroundColor: 'transparent'},
                            '& .MuiButton-root:hover': {
                            backgroundColor: 'secondary.main',
                            }
                        }}>
                            {!char?.new && 
                            <Button
                                disabled={props.readonly}
                                component="label"
                                sx={{
                                    width: '100%',
                                    border: '1px solid rgba(255, 255, 255, 0.7)',
                                    backgroundColor: 'transparent',
                                }}
                                text={
                                    <>
                                        <Box
                                            sx={{
                                                backgroundSize: 'cover',
                                                width: '350px',
                                                height: '350px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                contain: 'content'
                                            }}
                                        >
                                            {uploadPortrait && (
                                                <img src={URL.createObjectURL(uploadPortrait)} alt="test" style={{height: '98%'}}/>
                                            )}
                                            {!uploadPortrait && (
                                                <img src={char?.imagePath ? STATIC_URL + '/uploads/' + char?.imagePath : NoPortrait} alt="test" style={{height: '98%'}}/>
                                            )}
                                        </Box>
                                        <br />
                                        <input type="file" accept=".png" hidden onChange={selectFile} />
                                    </>
                                }
                            />
                            }
                            {char?.new && 
                                <Box
                                    sx={{
                                        backgroundSize: 'cover',
                                        width: '350px',
                                        height: '350px',
                                        border: '1px solid rgba(255, 255, 255, 0.7)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        contain: 'content',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography
                                        variant="h2" 
                                        sx={{ 
                                        fontFamily: 'Bebas',
                                        fontSize: '18pt', 
                                        color: 'primary.main',
                                        }}
                                    >
                                        {t('Generic.PortraitAfterCreation')} 
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                    <Typography component="p" sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'rgba(255, 82, 82, '+ showHint + ')'
                    }}>
                        {t('Generic.PortraitNotYetSaved')} 
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="char.description"
                        defaultValue={char?.description}
                        multiline
                        label={t('Components.PlayerCharacters.Description')}
                        rows={10}
                        onChange={(event) => setChar({...char, description: event.target.value})}
                        helperText={`${char?.description?.length ?? 0}/${TEXT_FIELD_BIG}`}
                        error={char?.description?.length > TEXT_FIELD_BIG}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        id="char.secretDescription"
                        defaultValue={char?.secretDescription}
                        multiline
                        label={t('Components.PlayerCharacters.Secrets')}
                        rows={10}
                        onChange={(event) => setChar({...char, secretDescription: event.target.value})}
                        helperText={`${char?.secretDescription?.length ?? 0}/${TEXT_FIELD_BIG}`}
                        error={char?.secretDescription?.length > TEXT_FIELD_BIG}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
