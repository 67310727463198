import { Box, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button } from 'components/ui';
import { Headline, RegisterForm, RegisterTokenEmail } from 'components/thestral';

import { HTTP_METHOD, REGISTRATION_URL } from 'const';
import { useFetch, useFetchFromBackend, useQuery, useSnackbar } from 'func';
import { useEffect, useState } from 'react';
import { Token } from 'types';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required(t('Components.Register.Required')),
  lastname: yup
    .string()
    .required(t('Components.Register.Required')),
  streetName: yup
    .string()
    .required(t('Components.Register.Required')),
  streetNumber: yup
    .string()
    .required(t('Components.Register.Required')),
  addressOptional: yup
    .string()
    .optional(),
  zipCode: yup
    .string()
    .required(t('Components.Register.Required'))
    .min(4, 'Components.Register.ZipCodeMin')
    .max(5, 'Components.Register.ZipCodeMax')
    .test(
      'zip-rules',
      t('Components.Register.ZipCodeNumbersOnly'),
      (value) => /\d{4,5}/.test(value)
    ),
  city: yup
    .string()
    .required(t('Components.Register.Required')),
  dateOfBirth: yup
    .date()
    .required(t('Components.Register.Required')),
  nickname: yup
    .string()
    .optional(),
  password: yup
    .string()
    .min(8, t('Components.Register.PasswordMin'))
    .max(32, t('Components.Register.PasswordMax'))
    .test(
      'character-rules',
      t('Components.Register.PasswordSatisfaction'),
      (value) => {
        const tests = [
          /[A-Z]/.test(value),
          /[a-z]/.test(value),
          /\d/.test(value),
          /[!@#$%^&*(),.?":{}|<>]/.test(value)
        ];
        return tests.filter(Boolean).length >= 3;
      }
    )
    .required(t('Components.Register.Required')),
  passwordConfirm: yup
     .string()
     .required()
     .oneOf([yup.ref('password'), null], t('Components.Register.PasswordMatch')),
  legal: yup
    .boolean()
    .oneOf([true], t('Components.Register.DataProtection.Required')),
  codeOfConductAccepted: yup
    .boolean()
    .oneOf([true], t('Components.Register.CodeOfConduct.Required')),
});

export function Register() {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();
  const nav = useNavigate(); 
  const query = useQuery();
  const token = query.get('token');
  const formik = useFormik({
    initialValues: {
      legal: false,
      codeOfConductAccepted: false
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values)
  });
  const { data, error, loading } = useFetch<Token>(`${REGISTRATION_URL}?token=${token}`);
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    if (data) {
      setEmail(data?.email);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showSnackbar(`${t('Components.Register.TokenInvalid')}`, 'error');
      nav('/');      
    }
  }, [error]);

  const onSubmit = (values) => {
    const body = JSON.stringify({...values, uuid: token, email});
    fetchFromBackend(REGISTRATION_URL, {method: HTTP_METHOD.POST, body})
    .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        let result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          result += t('Components.Register.Success');
        }
        showSnackbar(`${t('Components.Register.Submit')} ${result}`, severity);
        if (response.ok) {
          setTimeout(() => nav('/'), 3000);
        }
    });
  };

  return (
    <Box className="content" sx={{marginTop: '50px', width: '900px'}}>
      {loading ? 
        <CircularProgress />   
      :
        <>
          <Headline title={t('Components.Greeting.Welcome')} marginBottom='10px' />
          <Typography component="div" variant="h6">
            {t('Components.Register.Caption')}
          </Typography>

          <RegisterTokenEmail token={token} email={email} />
          <form onSubmit={formik.handleSubmit}>
            <RegisterForm formik={formik} />

            <Button 
              color="success" 
              onClick={formik.submitForm} 
              sx={{marginTop: '20px'}} 
              text={t('Generic.Save')} 
            />
          </form>
        </>
      }
    </Box>
  );
}
