import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  title: string,
}
export function TabHeadline(props: Readonly<Props>) : ReactNode {
  return (
    <Typography
      variant="h1" 
      component="p" 
      sx={{ 
        fontSize: '2rem',
        fontWeight: '400',
        textAlign: 'left',
        lineHeight: '50px'
      }}>
        {props.title}
    </Typography>
  );
}