import { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { Button, DateField, Dialog, TextField } from 'components/ui';
import { CardCircularProgress } from 'components/thestral';

import { Event } from 'types';
import { useFetchFromBackend } from 'func';
import { EVENTS_URL, HTTP_METHOD } from 'const';
import { DateTime } from 'luxon';

type Props = {
  open: boolean,
  event: Event | null
  onClose: () => void,
  triggerRefetch: () => void
  showSnackbar?: (msg, severity) => void
}

const validationSchema = yup.object({
  name: yup.string().required(t('Components.Register.Required')),
  place: yup.string().optional(),
  start: yup.date().required(t('Components.Register.Required')),
  end: yup.date().required(t('Components.Register.Required'))
});
export function EventDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const [loading, setLoading] = useState<boolean>();

  const saveEvent = (values) => {
    const body =  JSON.stringify(values as Event);
    const id = props.event ? `/${props.event.id}` : '';

    fetchFromBackend(`${EVENTS_URL}${id}`, {
      method: props.event ? HTTP_METHOD.PUT : HTTP_METHOD.POST,
      body
    }).then((response) => {
      const severity = response.ok ? 'success' : 'error';
      const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

      props.showSnackbar(`${'Generic.ApprovalChange'} ${result}`, severity);
      props.triggerRefetch();
      props.onClose();
    })
    .catch((error) => console.error(error))
    .finally(() => {
      setLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      name: props.event?.name ?? '',
      place: props.event?.place ?? '',
      start: props.event?.start ?? DateTime.now().toISODate(),
      end: props.event?.end ?? DateTime.now().toISODate()
    },
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: saveEvent
  });

  const actions = () => {
    if (loading) return <></>;

    return (
      <Button 
        onClick={formik.submitForm}
        color="success" 
        sx={{ marginLeft: '5px' }} 
        text={t('Components.Admin.Events.Save')} 
      />
    );
  };

  const content = () => {
    if (loading) {
      return (
        <CardCircularProgress />
      );
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField 
              id="name"
              label={t('Generic.Name')}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.name)}
              helperText={formik.errors.name as string}
              value={formik.values.name}
            /> 
          </Grid>
          <Grid item xs={6}>
            <TextField 
              id="place"
              label={t('Components.Events.Location')}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.place)}
              helperText={formik.errors.place as string}
              value={formik.values.place}
            /> 
          </Grid>

          <Grid item xs={6}>
            <DateField 
              label={t('Components.Events.Start')} 
              onChange={(value) => formik.setFieldValue('start', value.toISODate())}
              value={formik.values.start}
              error={Boolean(formik.errors.start)}
              helperText={formik.errors.start as string}
              maxDate={formik.values.end}
              disablePast
            />
          </Grid>
          <Grid item xs={6}>
            <DateField 
              label={t('Components.Events.End')} 
              onChange={(value) => formik.setFieldValue('end', value.toISODate())}
              value={formik.values.end}
              error={Boolean(formik.errors.end)}
              helperText={formik.errors.end as string}
              minDate={formik.values.start}
              disablePast
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}
      title={t('Components.Admin.Events.New')}
      actions={actions()}
      content={content()}
    />
  );
}
